import React from 'react';
import Dashboard from '../Dashboard';
const Testimonials = () => {
  return (
    <Dashboard>
      <div className="mt-20">Testimonials</div>
    </Dashboard>
  );
};

export default Testimonials;
