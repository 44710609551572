import React from 'react';
import equipmentImage from '../Assets/image/equipments.png';
import Layout from '../Components/layout/Layout';
import Gallery from '../Components/Gallery';

const OurEquipments = () => {
  return (
    <Layout>
      <section
        id="equipments"
        className="w-full flex items-center justify-center bg-gray-800 py-12"
      >
        <div className="container mx-auto flex items-center px-6">
          <div className="item2 text-left mb-6">
            <div className="item1 mb-6 text-center">
              <p className="text-3xl font-bold text-yellow-400 mb-4 special-elite-regular text-3xl font-semibold text-gray-800 mb-2">
                OUR EQUIPMENTS
              </p>
            </div>
            <p className="text-gray-200 mb-4">
              At Gaia Survey Rwanda, second only to the skilled people who operate them, we maintain PRD Speed Star and Gemsa drill rigs, carrier trucks,
              down-hole equipment, and support gear, enabling us to use a wide range of drilling methods and configurations. 
            </p>
            <p className="text-gray-200 mb-4">
              Our equipment is serviced by a highly qualified and certified mechanical team at our new maintenance facility in Kigali-Remera, located at our offices.
            </p>
            <h2 className="text-xl font-semibold text-yellow-400 mt-4 mb-2">Open Loop</h2>
            <p className="text-gray-200">
              As one of the foremost water well drilling contractor in Rwanda, we are
              ideally suited to handle these types of projects, particularly in cases of limited
              access.
            </p>
          </div>
          <div className="item3 flex justify-center w-full">
            <img
              src={equipmentImage}
              alt="Construction Equipment"
              className="w-1/2 rounded-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>
      <Gallery />
    </Layout>
  );
};

export default OurEquipments;
