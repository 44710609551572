import React from 'react';
import { useHistory } from 'react-router-dom';

const Home = () => {
  const history = useHistory();
  const handleRedirect = (link) => {
    history.push(link);
  };

  const menuItems = [
    {
      id: 1,
      title: 'Our Services',
      description:
        'We carry out all small and large commercial borehole drilling operations. We also construct boreholes for irrigation systems, High quality engineering...',
      redirectPath: '/service',
      itemClass: 'item-2'
    },
    {
      id: 2,
      title: 'Our Projects',
      description:
        'Gaia Survey Rwanda is a division of Gaia Survey AB Sweden, Rwandan registered company which specializes in hydro-Geology Surveys and borehole drilling.',
      redirectPath: '/project',
      itemClass: 'item-1'
    },
   
    {
      id: 3,
      title: 'Our Mission',
      description:
        'To provide innovative, high-quality solutions at competitive prices, ensuring safe work environments and timely project delivery that exceed client expectations.',
      redirectPath: '/mission',
      itemClass: 'item-3'
    },
    {
      id: 3,
      title: 'Our Vision',
      description:
        'To be a trusted leader in drilling and engineering, delivering exceptional solutions that go beyond client expectations.',
      redirectPath: '/vision',
      itemClass: 'item-6'
    },
    {
      id: 4,
      title: 'Our Equipments',
      description:
        'At Gaia Survey Rwanda, second only to the skilled people who operate them, we maintain PRD Speed Star and Gemsa drill rigs, carrier trucks, down-hole equipment, and support gear, enabling us to use a wide range of drilling methods and configurations. Our equipment is serviced by a highly qualified and certified mechanical team at our new maintenance facility in Kigali-Remera, located at our offices.',
      redirectPath: '/equipment',
      itemClass: 'item-4'
    },
    {
      id: 5,
      title: 'Our Clients',
      description:
        'Our clients include a variety of essential institutions such as government agencies, educational establishments, hospitals, and universities, all of which rely on our expertise in drilling and borehole services. We are dedicated to providing tailored solutions that meet the unique needs of these sectors, ensuring their projects are executed with precision and efficiency to support their mission-driven initiatives.',
      redirectPath: 'client',
      itemClass: 'item-5'
    }
  ];

  return (
    <div className="">
      <div className="w-full">
        <section className="hero h-screen">
          <div className="background">
            <h1 className="mt-4 font-bold text-xl special-elite-regular leading-relaxed">
              GAIA OVERVIEW
            </h1>
            <p className="mt-2 text-gray-640 pr-5 text-left">
              <b>Gaia Survey Rwanda</b> is a division of Gaia Survey AB Sweden, specializing in the construction of wells/boreholes, water pipeline installations, and the setup of various pump systems.
              We also offer water treatment, storage solutions, and provide ongoing maintenance and management for both public and private wells.
            </p>

            <a
              href="#contact"
              className="block fit-conet special-elite-regular py-1.5 pl-3 pr-4 font-poppins text-black rounded md:hover:bg-transparent md:border-0 md:hover:text-primary transition duration-0 hover:duration-75 md:p-0"
            >
              CONTACT US ONLINE
            </a>
          </div>
        </section>
        <section class="menu">
          {menuItems.map((item) => (
            <div
              key={item.id}
              className="menu-item relative bg-white p-6 pt-20 text-center rounded-lg shadow-md"
            >
              <div
                className={`${item.itemClass}  relative md:absolute w-36 h-36 rounded-full absolute top-3 md:-top-32 left-1/2 hover:border-gray-400 transform -translate-x-1/2 border-4 border-white shadow-lg cursor-pointer`}
                onClick={() => handleRedirect(item.redirectPath)}
              ></div>
              <h2 className="mt-4 font-bold text-xl">{item.title}</h2>
              <p className="mt-2 text-gray-640">{item.description.slice(0, 100)}...</p>
              <button onClick={() => handleRedirect(item.redirectPath)}>View More</button>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Home;
