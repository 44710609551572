import React from 'react';
import Dashboard from '../Dashboard';
const Donation = () => {
  return (
    <Dashboard>
      <div className="mt-20">Donation</div>
    </Dashboard>
  );
};

export default Donation;
