import React from 'react';
import imageUrl from '../Assets/image/clients.PNG';
import Layout from '../Components/layout/Layout';
const OurClients = () => {
  return (
    <Layout>
      <div
        className="client w-full h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: '80%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      ></div>
    </Layout>
  );
};

export default OurClients;
