import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import HttpsRedirect from 'react-https-redirect';
import '@hookstate/devtools';
require('dotenv').config();

Sentry.init({
  dsn: 'https://3c0a0cc5735a460e949dd76cb401c621@o1262298.ingest.sentry.io/6440997',
  integrations: [new BrowserTracing()],

  tracesSampleRate: 1.0
});
dotenv.config();
ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);
