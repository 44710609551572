import React from 'react';
import missionImage from '../Assets/image/equipments.png';

const OurEquipments = () => {
  return (
    <section
      id="equipments"
      className="w-full flex items-center justify-center bg-gray-800 py-12"
    >
      <div className="container mx-auto flex items-center px-6">
        <div className="item2 text-left mb-6">
          <div className="item1 mb-6 text-center">
            <p className="text-3xl font-bold text-yellow-400 mb-4">OUR EQUIPMENT</p>
          </div>
          <h1 className="">
            At Gaia Survey Rwanda, second only to the skilled people who operate them, we maintain PRD Speed Star and Gemsa drill rigs, carrier trucks,
            down-hole equipment, and support gear, enabling us to use a wide range of drilling methods and configurations. 
          </h1>
          <p className="text-gray-200 mb-4">
            Our equipment is serviced by a highly qualified and certified mechanical team at our new maintenance facility in Kigali-Remera, located at our offices.
          </p>
          <h2 className="text-xl font-semibold text-yellow-400 mt-4 mb-2">Open Loop</h2>
          <p className="text-gray-200">
            As one of the foremost water well drilling contractors in Sweden and Rwanda, we are
            ideally suited to handle these types of projects, particularly in cases of limited
            access.
          </p>
        </div>
        <div className="item3 flex justify-center w-full">
          <img
            src={missionImage}
            alt="Construction Equipment"
            className="w-1/2 h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default OurEquipments;
