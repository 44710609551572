import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Layout = (props) => {
  return (
    <div>
      <div className="fixed w-full z-20">
        <Header />
      </div>
      <div className="fixed right-0 z-20 top-1/4 flex flex-col items-center space-y-4">
        <a
          href="https://web.facebook.com/gaiasurveyrwanda"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-blue-600 bg-gray-400 rounded-full p-2 hover:bg-primary h-8 w-8" />
        </a>
        <a
          href="https://x.com/gaiasurvey"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-blue-400 bg-gray-400 rounded-full p-2 hover:bg-primary h-8 w-8" />
        </a>
        <a
          href="https://www.linkedin.com/company/gaia-survey-rwanda"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn className="text-blue-700 bg-gray-400 rounded-full p-2 hover:bg-primary h-8 w-8" />
        </a>
        <a
          href="https://www.instagram.com/gaiasurveyrwanda"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-pink-500 bg-gray-400 rounded-full p-2 hover:bg-primary  h-8 w-8" />
        </a>
      </div>

      <main className="w-full pt-16 z-10">{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
