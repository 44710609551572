import React from 'react';
import imageUrl from '../Assets/image/clients.PNG';
const OurClients = () => {
  return (
    <div
      className="w-full bg-cover bg-center hero-client"
      
    ></div>
  );
};

export default OurClients;
