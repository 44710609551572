import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import Map from '../Components/OurMap';
import contactImage from '../Assets/icon/contactusg.png';
import Layout from '../Components/layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <div className="bg-gray-200">
        <div className="flex w-full">
          <div
            className="bg-contact w-full block md:flex bg-cover bg-gray-200 bg-center bg-no-repeat justify-center py-4"
            id="contact"
          >
            <div className="w-full md:w-1/2 bg-opacity-80 rounded-lg special-elite-regular mb-2 max-w-3x mx-4 p-2 lg:p-12">
              <img src={contactImage} alt="Contact" className="w-64 h-64 rounded-lg mb-4" />

              <div className="flex items-center mb-2">
                <AiOutlineMail className="h-5 w-5 text-yellow-400 mr-2" />
                <a
                  href="mailto:info@gaiasurveyrwanda.com"
                  className="text-gray-500 hover:underline"
                >
                  info@gaiasurveyrwanda.com
                </a>
              </div>

              <div className="flex items-center mb-2">
                <FiPhone className="h-5 w-5 text-yellow-400 mr-2" />
                <a href="tel:+250790139822" className="text-gray-500 hover:underline">
                  (+250) 788 319 261
                </a>
              </div>

              <div className="flex items-center mb-4">
                <GoLocation className="h-5 w-5 text-yellow-400 mr-2" />
                <p className="text-gray-500">RN3, Kigali, Rwanda</p>
              </div>
            </div>
            <div className="bg-opacity-80 rounded-lg shadow-lg max-w-3xl w-full md:w-1/2 mx-4 p-2">
              <div className="text-center mt-12">
                <h1 className="text-3xl lg:text-4xl font-bold text-yellow-400  special-elite-regular mb-2">
                  GET IN TOUCH
                </h1>
                <p className="text-gray-400 text-sm lg:text-base mb-4">
                  Have questions or need a quote? Fill out the form below, and we’ll get back to you
                  as soon as possible.
                </p>
              </div>
              <form className="space-y-2 mx-4">
                <div className="lg:flex lg:space-x-4">
                  <input
                    className="bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-500 rounded-lg py-3 px-4 w-full lg:w-1/2"
                    type="text"
                    placeholder="Name"
                    required
                  />
                  <input
                    className="bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-500 rounded-lg py-3 px-4 w-full lg:w-1/2 mt-4 lg:mt-0"
                    type="email"
                    placeholder="Email address"
                    required
                  />
                </div>
                <textarea
                  className="bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-500 rounded-lg py-1 px-4 w-full h-36"
                  placeholder="Your message..."
                  required
                ></textarea>
                <div className="text-right">
                  <button
                    type="submit"
                    className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 font-semibold rounded-lg py-3 px-6 transition duration-300 transform hover:scale-105"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Map />
      </div>
    </Layout>
  );
};

export default Contact;
