import React from 'react';
import Logo from '../Assets/image/logo.png';
import {
  HiChevronRight,
  HiLocationMarker,
  HiOutlineDeviceMobile,
  HiOutlineMail,
  HiOutlinePhone
} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer aria-label="Site Footer" className="bg-gray-100">
      <div className="max-w-screen-xl px-4 py-4 mx-auto space-y-8 sm:px-6 lg:space-y-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="flex flex-col items-center">
            <div className="text-teal-600">
              <Link to="/">
                <img className="h-12 object-cover mt-4" src={Logo} alt="logo" />
              </Link>
            </div>

            <p className="max-w-xs mt-4 text-gray-400 font-rubik text-center">
              We carry out all large commercial borehole drilling operations.
            </p>

            <ul className="flex gap-4 mt-8">
              <li>
                <a
                  href="https://web.facebook.com/gaiasurveyrwanda"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75 flex items-center"
                >
                  <span className="sr-only">Facebook</span>
                  <FaFacebookF className="text-blue-600 bg-primary rounded-full p-2 hover:text-blue-800 h-8 w-8" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/gaiasurvey"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75 flex items-center"
                >
                  <span className="sr-only">Twitter</span>
                  <FaTwitter className="text-blue-700 bg-primary rounded-full p-2 hover:text-blue-900 h-8 w-8" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/gaia-survey-rwanda"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75 flex items-center"
                >
                  <span className="sr-only">Linkedin</span>
                  <FaLinkedinIn className="text-blue-700 bg-primary rounded-full p-2 hover:text-blue-900 h-8 w-8" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/gaia-survey-rwanda"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75 flex items-center"
                >
                  <span className="sr-only">LinkedIn</span>
                  <a
                    href="https://www.instagram.com/gaiasurveyrwanda"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="text-pink-500 bg-primary rounded-full p-2 hover:text-pink-700 h-8 w-8" />
                  </a>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3">
            <div className="flex flex-col items-center">
              <div className="space-y-4 text-sm text-left">
                <p className="font-lato text-gray-400 pl-2 text-xl text-left">Our Company</p>
                <p className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <Link
                    to="/about"
                    className="text-gray-400 text-xs transition font-rubik hover:opacity-75 pl-4"
                  >
                    Who We Are
                  </Link>
                </p>
                <p className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <Link
                    to="/about"
                    className="text-gray-400 font-rubik text-xs transition hover:opacity-75 pl-4"
                  >
                    About Company
                  </Link>
                </p>
                <p className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <Link
                    to="/service"
                    className="text-gray-400 font-rubik text-xs transition hover:opacity-75 pl-4"
                  >
                    Services We Provide
                  </Link>
                </p>
                <p className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <Link
                    to="/contact"
                    className="text-gray-400 font-rubik text-xs transition hover:opacity-75 pl-4"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="space-y-4 text-sm text-left">
                <p className="font-lato text-gray-400 pl-2 text-xl text-left">Our Services</p>

                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">
                    Underground Water Detecting
                  </p>
                </div>
                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">Drilling And Exploration</p>
                </div>
                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">Pump Installations</p>
                </div>
                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">Irrigation Schame</p>
                </div>
                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">
                    Rain Water Harvesting And Treatment
                  </p>
                </div>
                <div className="flex items-center text-left">
                  <HiChevronRight className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">
                    Water Pipelines And Supply
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="space-y-4 text-sm text-left">
                <p className="font-lato text-gray-400 pl-2 text-xl text-left">Contact Info</p>
                <div className="flex items-center text-left">
                  <HiLocationMarker className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">
                    RN3, Kigali - Kigali, Rwanda
                  </p>
                </div>
                <div className="flex items-center text-left">
                  <HiOutlineMail className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">info@gaiasurveyrwanda.com</p>
                </div>
                <div className="flex items-center text-left">
                  <HiOutlinePhone className="h-6 w-6 pt-2 text-primary" />
                  <p className="text-gray-400 font-rubik text-xs pl-4">(+250) 788 319 261</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-8 border-gray-400" />

        <p className="text-center text-gray-400 text-xs">
          © {new Date().getFullYear()} Gaia Survey Rwanda. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
