import React from 'react';
import icon1 from '../Assets/image/drilling-icon-1.png';
import imag1 from '../Assets/image/drilling.png';
import icon2 from '../Assets/image/construction-3.png';
import img2 from '../Assets/image/pipeline-img.PNG';
import icon3 from '../Assets/image/water.png';
import img3 from '../Assets/image/services-mini-piles.jpg';
import { useHistory } from 'react-router-dom';

const services = [
  {
    title: 'Drilling of Water Wells',
    icon: icon1,
    image: imag1,
    description:
      'Gaia Survey Rwanda provides expert drilling, completion, and rehabilitation of water wells for municipal, commercial, industrial, residential, and agricultural use. Our solutions are tailored to meet specific water needs, ensuring reliable, long-term access to clean water.Specialized drilling services to access deep water wells for sustainable water solutions.'
  },
  {
    title: 'Water Pipeline and Supply',
    icon: icon2,
    image: img2,
    description:
      'We specialize in the installation of water pipelines, ensuring efficient water transportation from storage to your property. Our team handles every step, delivering high-quality, durable, and reliable water supply systems for homes, businesses, and industries.'
  },
  {
    title: 'Open Hole and Micro Piling',
    icon: icon3,
    image: img3,
    description:
      'We provide open-hole and micro-piling solutions for foundation stability in construction projects. Our services ensure robust structural support, catering to both commercial and residential needs with advanced engineering techniques.',
  },
];

const OurServices = () => {
  const history = useHistory();
  const handleRedirect = (link) => {
    history.push(link);
  };
  return (
    <div className="bg-gray-200 text-gray-100 py-12 px-6">
      <div className=" w-full mx-auto text-center">
        <h2 className="text-4xl font-bold text-yellow-400 mb-8">Our Services</h2>
        <div className="flex gap-6 justify-center overflow-hidden  scroll-smooth">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-lg p-6 text-center transition hover:-translate-y-2 hover:bg-gray-700 flex-none w-380"
            >
              <img
                src={service.icon}
                alt={`${service.title} Icon`}
                className="w-32 h-32 mx-auto mb-4"
              />
              <h3 className="font-semibold text-yellow-400 mb-3">{service.title}</h3>
              <img src={service.image} alt={service.title} className="w-full h-1/2 rounded mb-4" />
              <p className="text-gray-300 text-sm">{service.description.slice(0, 100)}...</p>
            </div>
          ))}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-yellow-500 text-gray-900 rounded hover:bg-yellow-400 transition duration-200"
          onClick={() => handleRedirect('/service')}
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default OurServices;
