import React, { useState } from 'react';
import Layout from '../Components/layout/Layout';
import { services } from '../utils/services.js';
import Contact from '../Components/Contact';

const ServicesPage = () => {
  return (
    <Layout>
      <div className="pb-24 pt-8 nanum-myeongjo-regular bg-gray-50 md:px-8 lg:px-32">
        <SectionHeader title="Our Services" />

        <div className="text-gray-700 mx-auto mt-8 lg:w-3/4 leading-relaxed text-center">
          <p className="font-medium">
            Gaia Survey Rwanda, part of Gaia Survey AB Sweden, specializes in hydro-geology
            surveys and borehole drilling. We handle commercial drilling projects, including
            irrigation boreholes, sampling, and mineral exploration.
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 lg:px-0">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

// Section Header Component
const SectionHeader = ({ title }) => (
  <div className="text-center mb-8">
    <h2 className="text-3xl special-elite-regular font-semibold text-gray-800 mb-2">{title}</h2>
    <hr className="w-32 h-1 mx-auto bg-yellow-400 rounded" />
  </div>
);

// Service Card Component with Modal
const ServiceCard = ({ name, description, subDescription, img }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div className="shadow-lg hover:shadow-2xl transition-shadow p-6 rounded-lg bg-white flex flex-col items-center text-center">
      <img className="rounded-full w-32 h-32 object-cover mb-4" src={img} alt={name} />
      <h3 className="text-xl font-bold text-gray-800 mb-2">{name}</h3>
      <p className="text-sm font-light text-gray-600 mb-4">{description.slice(0, 100)}...</p>
      <button className="text-primary font-semibold underline" onClick={toggleModal}>
        View More
      </button>

      {showModal && (
        <div className="fixed inset-0 h-z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-lg w-full p-8 relative">
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-600 font-bold text-xl"
            >
              &times;
            </button>
            <h3 className="text-2xl text-gray-400 font-bold mb-4">{name}</h3>
            <p className="text-md font-light text-gray-700 mb-2">{description}</p>
            <p className="text-sm font-light text-gray-500">{subDescription}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesPage;
