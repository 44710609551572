import React from 'react';
import missionImage from '../Assets/image/mission.jpg';
import Layout from '../Components/layout/Layout';
import Gallery from '../Components/Gallery';

const OurMisssion = () => {
  return (
    <Layout>
      <section
        id="mission"
        className="w-full flex items-center justify-center bg-gray-800 py-12 hero-mission"
      >
        <div className="container mx-auto flex items-center px-6">
          <div className="item2 text-left mb-6">
            <div className="item1 mb-6 text-center">
              <p className="text-3xl font-bold text-yellow-400 mb-4 special-elite-regular text-3xl font-semibold text-gray-800 mb-2">
                OUR MISSION
              </p>
            </div>
            <p className="text-gray-200 mb-4">
            At Gaia Survey Rwanda, our mission is to deliver cutting-edge drilling, water well, 
            and engineering solutions with precision, efficiency, and sustainability.
            </p>
            <p className="text-gray-200 mb-4">
            We are committed to empowering government and private sector clients by providing 
             innovative and reliable services that address modern water and engineering challenges,
             ensuring excellence and fostering long-term partnerships.
            </p>
          </div>
          <div className="item3 flex justify-center w-full">
            {/* <img
              src={missionImage}
              alt="Construction Mission"
              className="w-1/2 rounded-full h-auto rounded-lg shadow-lg"
            /> */}
          </div>
        </div>
      </section>
      <Gallery />
    </Layout>
  );
};

export default OurMisssion;
