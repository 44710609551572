import React from 'react';
import waterWellImage from '../Assets/image/drilling-icon.png';

const WaterWells = () => {
  return (
    <section id="hero" className="w-full">
      <div className="container mx-auto flex items-center px-6">
        <div className="item3 flex justify-center w-full">
          <img
            src={waterWellImage}
            alt="Construction Equipment"
            className="w-1/2 h-auto rounded-lg shadow-lg"
          />
        </div>

        <div className="item2 text-left mb-6">
          <div className="item1 mb-6 text-center">
            <p className="text-3xl font-bold text-yellow-400 mb-4">WATER WELLS</p>
          </div>
          <h1 className="text-gray-400 mb-4">
            The escalating cost of mains water is having an increasing impact on many activities
            that have a high water usage.
          </h1>
          <p className="text-gray-400 mb-4">
            Mains water costs alone can account for annual water bills in excess of tens or even
            hundreds of thousands of dollars.
          </p>
          <h2 className="text-xl font-semibold text-yellow-400 mt-4 mb-2">Water Well</h2>
          <p className="text-gray-400 mb-4">
            In many areas, there are huge reservoirs of groundwater (or aquifers) that could easily
            be tapped into, producing significant operational savings. By drilling into these
            aquifers, Gaia survey has provided our clients with an independent supply of abundant
            good quality water, automatically controlled to be delivered at the pressure required.
          </p>
          <p className="text-gray-400 mb-4">
            This has saved our clients hundreds of thousands of dollars. Our client sectors are
            wide-ranging and include:
          </p>
          <ul className="list-disc list-inside text-gray-500 mb-4">
            <li>Municipality and Sports Stadiums</li>
            <li>Pharmaceutical Firms</li>
            <li>Farms</li>
            <li>Hospitals</li>
            <li>Food and Drink Processing Plants</li>
            <li>Factories</li>
            <li>Abattoirs</li>
            <li>Caravan Sites</li>
            <li>Schools</li>
          </ul>
          <p className="text-gray-400">
            Whatever your business, if you use large volumes of water, we can probably save you
            money.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WaterWells;
