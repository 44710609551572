import React from 'react';
import { useHistory } from 'react-router-dom';
import comingSoonImage from '../Assets/image/commingsoon.webp'; // Replace with your image path

function ComingSoon() {
  const history = useHistory();

  const handleReturnHome = () => {
    history.push('/');
  };
  return (
    <div className="hero flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800 p-4">
      <img
        src={comingSoonImage}
        alt="Coming Soon"
        className="w-64 h-64 object-cover rounded-full mb-6 shadow-lg"
      />
      <h3 className="text-3xl z-2 font-bold mt-4 text-center special-elite-regular">
        We're working hard to bring something amazing to you!
      </h3>
      <button
        onClick={handleReturnHome}
        className="font-bold text-xl special-elite-regular py-1.5 pl-3 pr-4 text-black rounded md:hover:bg-transparent md:border-0 md:hover:text-primary transition duration-0 hover:duration-75 md:p-0"
      >
        Return Home
      </button>
    </div>
  );
}

export default ComingSoon;
