import React from 'react';
import conductorImage from '../Assets/image/drilling_eq1.jpg';

const ConductorCasings = () => {
  return (
    <section id="hero" className="w-full bg-gray-800 py-12">
      <div className="container mx-auto flex items-center px-6">
        <div className="item2 text-left mb-6">
          <div className="item1 mb-6 text-center">
            <p className="text-3xl font-bold text-yellow-400 mb-4">CONDUCTOR CASINGS</p>
          </div>

          <h1 className="">
            Deep boreholes require big powerful drilling rigs that have a high daily running cost.
          </h1>
          <p className="text-gray-200 mb-4">
            The top part of a borehole consists of drilling through loose and broken formations,
            possible mine workings, and it is often an Environment Agency requirement to have
            important aquifers isolated.
          </p>
          <p className="text-gray-200 mb-4">
            This is time-consuming work and often requires several casings to be installed and
            grouted. Therefore, it is not cost-effective to have the main rig carry out works on
            this section of the borehole.
          </p>
          <p className="text-gray-200 mb-4">
            Gaia Survey regularly constructs this section of a borehole in advance and has
            heavy-duty machines capable of installing and grouting large diameter conductor casings,
            leaving a borehole prepared and ready for the powerful deep drilling rig to rapidly
            complete the remainder of the borehole.
          </p>
        </div>
        <div className="item3 flex justify-center w-full">
          <img
            src={conductorImage}
            alt="Construction Equipment"
            className="w-1/2 h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default ConductorCasings;
