import React, { useState } from 'react';
import image1 from '../Assets/image/Wellhead-borehole.jpg';
import image2 from '../Assets/image/water-wells-5.jpg';
import image3 from '../Assets/image/vision.jpg';
import image4 from '../Assets/image/sedfgh.jpg';
import image5 from '../Assets/image/wedfgh.jpg';
import image6 from '../Assets/image/equipments.png';
import image7 from '../Assets/image/asdfbn.jpg';
import image8 from '../Assets/image/water-well_drilling-company03.jpg';
import image9 from '../Assets/image/solar-livestock-watering-system-250_480x480.jpg';
import image10 from '../Assets/image/PolyPipe-Irrigation1.jpg';
import image11 from '../Assets/image/stock-photo-chiller-water-pump-chiller-water-pump-in-the-basement-286578356.jpg';
import image12 from '../Assets/image/jjjk,n.jpg';

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <section className="w-full bg-gray-800 py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-yellow-400 mb-8 text-center">
          Activity Highlights and Equipments Gallery
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-yellow-200 z-1 lg:grid-cols-4 gap-4">
          {images.map((pic, index) => (
            <div
              key={index}
              className="group overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105"
            >
              <img
                src={pic}
                alt={`item ${index + 1}`}
                className="w-full h-full object-cover rounded-lg shadow-lg duration-300 group-hover:scale-105 cursor-pointer "
                onClick={() => setSelectedImage(pic)}
              />
            </div>
          ))}
        </div>

        {/* Lightbox Modal */}
        {selectedImage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative max-w-2xl mx-auto">
              <button
                className="absolute top-2 right-2 text-white text-3xl font-bold focus:outline-none"
                onClick={() => setSelectedImage(null)}
              >
                &times;
              </button>
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Gallery;
