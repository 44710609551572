import React from 'react';
import founderAvatar from '../Assets/avatars/default-avatar.png';
import cofounderAvatar from '../Assets/avatars/default-avatar.png';
import teamAvatar from '../Assets/avatars/default-avatar.png';

const teamData = [
  {
    id: 1,
    name: 'Ian Gotthard ',
    title: 'Founder & Chairman',
    description: 'Visionary leader and founder of Gaia Survey with 20 years in hydro-geology.',
    avatar: founderAvatar
  },
  {
    id: 2,
    name: 'NGENZI Emmanuel',
    title: 'Co-Founder & COO',
    description:
      'Operational strategist, overseeing daily operations with extensive field expertise.',
    avatar: cofounderAvatar
  },
  {
    id: 3,
    name: 'NTAKIYIMANA Ernest',
    title: 'Lead Engineer',
    description: 'Experienced engineer specializing in borehole drilling, project management, and technical innovation.',
    avatar: teamAvatar
},
{
    id: 4,
    name: 'KALINDA Willfred',
    title: 'Field Operations & Asset Manager',
    description: 'Expert in managing field operations, equipment logistics, and asset optimization.',
    avatar: teamAvatar
},
{
    id: 5,
    name: 'ONGOMA Patrick',
    title: 'Drilling Operations Manager',
    description: 'Skilled professional overseeing drilling operations, site coordination, and safety compliance.',
    avatar: teamAvatar
},
{
    id: 6,
    name: 'MUNYANEZA Valens',
    title: 'Finance Manager',
    description: 'Finance specialist ensuring budget planning, cost control, and financial reporting.',
    avatar: teamAvatar
}
 
];

const TeamPage = () => {
  return (
    <div className="bg-gray-50 py-2 px-4 md:px-8 lg:px-32">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-gray-800 special-elite-regular">
          Executive Leadership
        </h2>
        <p className="text-gray-600 mt-4">
          Meet the talented individuals driving Gaia Survey forward.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {teamData.map((member) => (
          <div
            key={member.id}
            className="flex flex-col items-center text-center bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow"
          >
            <img
              src={member.avatar}
              alt={member.name}
              className="rounded-full w-24 h-24 mb-4 object-cover"
            />
            <h3 className="text-xl font-bold text-gray-800">{member.name}</h3>
            <p className="text-primary font-medium mb-2">{member.title}</p>
            <p className="text-sm text-gray-600">{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
