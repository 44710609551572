import React from 'react';
import visionImage from '../Assets/image/vision.jpg';
import Layout from '../Components/layout/Layout';
import Gallery from '../Components/Gallery';

const OurVision = () => {
  return (
    <Layout>
      <section
        id="vision"
        className="w-full flex items-center justify-center bg-gray-800 py-12 hero-vision"
      >
        <div className="container mx-auto flex items-center px-6">
          <div className="item2 text-left mb-6">
            <div className="item1 mb-6 text-center">
              <p className="text-3xl font-bold text-yellow-400 mb-4 special-elite-regular text-3xl font-semibold text-gray-800 mb-2">
                OUR VISION
              </p>
            </div>
            <p className="text-gray-200 mb-4">
            At Gaia Survey Rwanda, our vision is to become the leading provider of sustainable 
            and innovative water and engineering solutions in Rwanda and beyond,
             setting the standard for excellence, reliability, and environmental stewardship in the industry. 
            </p>
            <p className="text-gray-200 mb-4">
            We are committed to empowering government and private sector clients by providing 
             innovative and reliable services that address efficience of water supply and management challenges,
             ensuring excellence and fostering long-term partnerships.
            </p>
          </div>
          <div className="item3 flex justify-center w-full">
            {/* <img
              src={visionImage}
              alt="Construction Mission"
              className="w-1/2 rounded-full h-auto rounded-lg shadow-lg"
            /> */}
          </div>
        </div>
      </section>
      <Gallery />
    </Layout>
  );
};

export default OurVision;
