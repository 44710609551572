import React, { useState, useEffect } from 'react';

const LiveCount = () => {
  const [count, setCount] = useState(0); // Initialize the count to 0
  const start = 1;
  const end = 200;
  const duration = 2000; // 3 seconds
  const interval = 100; // Frame duration in ms

  useEffect(() => {
    const totalFrames = duration / interval;
    const increment = (end - start) / totalFrames;
    let current = start;

    const timer = setInterval(() => {
      current += increment;
      if (current >= end) {
        setCount(end);
        clearInterval(timer); // Stop the interval when count reaches the end
      } else {
        setCount(Math.floor(current)); // Update state with the current count
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="text-gray-800 py-8" style={{ fontSize: '2em', fontWeight: 'bold', textAlign: 'center' }}>
      {count} + <span>projects</span>
    </div>
  );
};

export default LiveCount;