import project1 from '../Assets/image/service1.png';
import projects2018 from '../Assets/image/projects2018.PNG';
import projects2019 from '../Assets/image/projects2019.PNG';
import projects2020 from '../Assets/image/projects2020.PNG';
import projects2021 from '../Assets/image/projects2021.PNG';
import projects2022 from '../Assets/image/projects2022.PNG';
import projects2023 from '../Assets/image/projects2023.PNG';
import projects2024 from '../Assets/image/projects2020.PNG';
import project3 from '../Assets/image/AAEAAQAAAAAAAAgYAAAAJGZiNjcwZTY1LTMwMzgtNGZkNy04NmNhLTc3ZjIxNDMyNzBkMA.jpg';
import project2 from '../Assets/image/drilling-for-wells.jpg';
import project4 from '../Assets/image/testPumping.PNG';
import projtestpumpvid from '../Assets/videos/testpumpvid.mp4';
import projevid1 from '../Assets/videos/videodrilling2.mp4';
import projevid2 from '../Assets/videos/videodrilling.mp4';
import projevid3 from '../Assets/videos/videodrilling1.mp4';
import projevid4 from '../Assets/videos/videotap.mp4';
import projevid5 from '../Assets/videos/videotank.mp4';
import project5 from '../Assets/image/PumpingInstallation.PNG';
export const currentProjects = [
  {
    name: 'Test Pumping',
    description:
      'A pumping test is a field experiment in which a well is pumped at a controlled rate',
    img: projtestpumpvid,
    videoUrl: 'https://www.example.com/video2.mp4',
    videoUrl: projtestpumpvid,
    url: 'https://marketplace-five-mu.vercel.app/'
  },
  {
    name: 'Geological and Hydro geological survey',
    description:
      'Geophysical survey considers different parameters such as hydrogeological aspects',
    img: project1,
    videoUrl: projevid1,
    url: 'https://skill-verification.vercel.app/'
  },
  {
    name: 'Drilling of water wells',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution',
    subDescription:
      'Gaia Survey Rwanda team is experienced in installation of water pipeline and its supply',
    img: project2,
    videoUrl: projevid2,
    url: 'https://bts-corp.github.io/EthFaucet/'
  },
  {
    name: 'Irrigation Schame',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution',
    subDescription:
      'Gaia Survey Rwanda team is experienced in installation of water pipeline and its supply',
    img: project2,
    videoUrl: projevid3,
    url: 'https://bts-corp.github.io/EthFaucet/'
  }
];

export const historicalProjects = [
  {
    name: 'Irrigation Schame',
    description:
      '',
    subDescription:
      '',
      img: project2,
      videoUrl: projevid4,
      url: 'https://bts-corp.github.io/EthFaucet/'
  },
  {
    name: '',
    description:
      '',
    img: project5,
    videoUrl: projevid5,
    url: 'https://bts-corp.github.io/Simple-Storage/'
  },

  {
    name: '',
    description:
      '',
    img: project5,
    videoUrl: projevid3,
    url: 'https://bts-corp.github.io/Simple-Storage/'
  }
];


export const projectsByYear = {
  current: [
    // {
    //   name: '',
    //   description:
    //     '',
    //   img: projects2024,
    //   videoUrl: 'projevid1.mp4',
    //   url: 'https://skill-verification.vercel.app/',
    // },
  ],
  2023: [
    {
      name: 'Drilling of water wells',
      description:
        '',
      subDescription:
        '',
      img: projects2023,
      videoUrl: 'projevid2.mp4',
      url: 'https://bts-corp.github.io/EthFaucet/',
    },
  ],
  2022: [
    {
      name: 'Irrigation Scheme',
      description:
        '.',
      subDescription:
        '',
      img: projects2022,
      videoUrl: 'projevid3.mp4',
      url: 'https://bts-corp.github.io/EthFaucet/',
    },
  ],
  2021: [
    {
      name: '',
      description:
        '',
      img:projects2021,
      videoUrl: 'projevid5.mp4',
      url: 'https://bts-corp.github.io/Simple-Storage/',
    },
  ],
  2020: [
    {
      name: '',
      description:
        '',
      img: projects2020,
      videoUrl: projects2020,
      url: 'https://bts-corp.github.io/Simple-Storage/',
    },
  ],
  2019: [
    {
      name: '',
      description:
        '',
      img: projects2019,
      videoUrl: 'projevid5.mp4',
      url: 'https://bts-corp.github.io/Simple-Storage/',
    },
  ],
  2018: [
    {
      name: '',
      description:
        '',
      img: projects2018,
      videoUrl: 'projevid5.mp4',
      url: 'https://bts-corp.github.io/Simple-Storage/',
    },
  ],
};
