import React from 'react';
import Home from '../Components/Home';
import Contact from '../Components/Contact';
import Layout from '../Components/layout/Layout';
import OurServices from '../Components/Ourservices';
import OurEquipments from '../Components/OurEquipments';
import WaterWells from '../Components/WaterWells';
import ConductorCasings from '../Components/ConductorCasing';
import LargeDBoreholes from '../Components/LargeDBoreholes';
import Gallery from '../Components/Gallery';
import OurClients from '../Components/OurClients';

const HomePage = () => {
  return (
    <div>
      <Layout>
        <Home />
        <OurEquipments />
        <OurServices />
        <OurClients />
        <WaterWells />
        <ConductorCasings />
        <LargeDBoreholes />
        <Gallery />
        <Contact />
      </Layout>
    </div>
  );
};

export default HomePage;
