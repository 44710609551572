import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Links } from '../Data';
import Logo from '../Assets/image/logo.png';

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="relative px-0.5 sm:px-0.5 py-3 z-2 bg-gray-200">
      <div className="flex flex-wrap items-center justify-between mx-auto ">
        <Link to="/" className="flex items-center">
          <img src={Logo} className="h-4 mr-3 sm:h-9 object-cover" alt="Logo" />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-1 ml-1 text-sm text-gray-500 hover:text-black rounded-lg lg:hidden hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={handleClick}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-2 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 items-center">
            {Links.map((item) => (
              <li>
                <NavLink
                  to={item.link}
                  className="block special-elite-regular py-2 pl-3 pr-4 font-poppins font-bold text-gray-500 rounded md:hover:bg-transparent md:border-0 md:hover:text-primary transition duration-0 hover:duration-75 md:p-0"
                  aria-current="page"
                  style={(isActive) => ({
                    color: isActive ? '#FE7D0F' : ''
                  })}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                  exact
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ul
        className={
          !open
            ? 'hidden'
            : 'absolute bg-footer w-full justify-end px-8 mt-2 pb-4 font-poppins right-0 z-10 transition-all duration-500 ease-in lg:hidden'
        }
        onClick={handleClick}
      >
        {Links.map((item) => (
          <li className="p-2 w-full mt-2 hover:text-primary">
            <NavLink
              to={item.link}
              style={(isActive) => ({
                color: isActive ? '#FE7D0F' : ''
              })}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
