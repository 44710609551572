import React from 'react';

function OpenLayersMap() {
  return (
    <div className="mx-10 rounded-xl">
      <iframe
        width="100%"
        height="343"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=520&amp;height=343&amp;hl=en&amp;q=GAIA%20SURVEY%20RN3,%20Kigali%20Kigali+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=80744f753f025bc07f1d55a8cb63e652528033a7"
      ></script>
    </div>
  );
}

export default OpenLayersMap;
