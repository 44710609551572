import React, { useState } from 'react';
import Layout from '../Components/layout/Layout';
import { currentProjects, Projects, projectsByYear } from '../utils/projects';
import Contact from '../Components/Contact';
import LiveCount from '../Components/LiveCount'

const ProjectsPage = () => {
  const [showCurrent, setShowCurrent] = useState('current');
  
    const [activeYear, setActiveYear] = useState('2023'); // Default to 'current' projects
  
    const handleTabClick = (year) => {
      setActiveYear(year);
    };

  const handleCurrProjTab = () => {
    setShowCurrent('current');
  };

  const handleHistProjTab = () => {
    setShowCurrent('history');
  };

  const downloadPDF = () => {
    const fileName = `${activeYear === 'Current' ? 'Current' : activeYear}.pdf`;
    const fileUrl = `/pdf/${fileName}`;

    // Trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };


  return (
    <Layout>
      <div className="pb-24 nanum-myeongjo-regular bg-gray-50 ">
        <div className="w-full  bg-gray-800 items-center flex justify-center hero-project">
          <SectionHeader title="Our Projects" />
        </div>
        <LiveCount/>

        {/* <div className="text-center my-6">
            <button
              onClick={handleCurrProjTab}
              className={`px-6 py-3 font-semibold rounded-l-lg transition text-gray-400 duration-300 ease-in-out ${
                showCurrent === 'current'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              Current Projects
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2023
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2022
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2021
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2020
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2019
            </button>
            <button
              onClick={handleHistProjTab}
              className={`px-6 py-3 font-semibold  transition text-gray-400 duration-300 ease-in-out ml-1 ${
                showCurrent === 'history'
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-600 text-gray-400 shadow-md'
              }`}>
              2018
            </button>
          </div> */}



          <div className="text-center my-6">
        {Object.keys(projectsByYear).reverse().map((year, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(year)}
            className={`px-6 py-3 font-semibold transition duration-300 ease-in-out ${
              activeYear === year
                ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-lg'
                : 'bg-gray-600 text-gray-400 shadow-md rounded-lg'
            } ml-1`}
          >
            {year === 'current' ? 'Current Projects' : year}
          </button>
        ))}
      </div>
          <div className="text-gray-700 mx-auto mt-8 lg:w-3/4 leading-relaxed text-center">
          {activeYear !== 'current' ?
              <p className="font-medium">
                The Following is {activeYear} projects of Gaia Survey Rwanda
              </p>:<p className="font-medium">
                Current projects will be uploaded soon!
              </p>
}
          
          </div>
         {/* Project Cards */}
      <div className="flex flex-wrap items-center justify-center">
        {projectsByYear[activeYear].map((project, index) => (
          <div
            key={index}
            className="border w-full md:w-3/4 h-auto rounded-lg shadow-lg p-4 hover:shadow-xl transition"
          >
            <img
              src={project.img}
              alt={project.name}
              className="w-full h-auto object-cover rounded-t-lg"
            />
            <h3 className="text-lg font-bold mt-2">{project.name}</h3>
            <p className="text-gray-600">{project.description}</p>
            {project.subDescription && (
              <p className="text-sm text-gray-500">{project.subDescription}</p>
            )}
            {/* Download Button */}
      <div className="text-center my-4">
        <button
          onClick={downloadPDF}
          className="text-primary font-semibold underline"
        >
          Download {activeYear === 'current' ? 'Current Projects' : `Projects ${activeYear}`} PDF
        </button>
      </div>
          </div>
        ))}
      </div>
      </div>
      <Contact />
    </Layout>
  );
};

// Section Header Component
const SectionHeader = ({ title }) => (
  <div className="text-center mb-8 mt-12 items-center justify-center">
    <h2 className="text-3xl special-elite-regular font-semibold text-gray-800 mb-2">{title}</h2>
    <hr className="w-32 h-1 mx-auto bg-yellow-400 rounded" />
  </div>
);

// Service Card Component with Modal
const ProjectCard = ({ name, description, subDescription, img, videoUrl }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div className="border border-2 border-gray-200 rounded-lg p-5">
      {videoUrl && (
        <div className="mt-4">
          <video controls className="w-full h-48 rounded-lg">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <h3 className="text-xl font-bold text-gray-800 mb-2">{name}</h3>
      <p className="text-sm font-light text-gray-600 mb-4">{description.slice(0, 100)}...</p>
      <button className="text-primary font-semibold underline" onClick={toggleModal}>
        View More
      </button>

      {showModal && (
        <div className="fixed inset-0 h-z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-lg w-full p-8 relative">
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-600 font-bold text-xl">
              &times;
            </button>
            <h3 className="text-2xl text-gray-400 font-bold mb-4">{name}</h3>
            <p className="text-md font-light text-gray-700 mb-2">{description}</p>
            <p className="text-sm font-light text-gray-500">{subDescription}</p>
            {videoUrl && (
              <div className="mt-4">
                <video controls className="w-full w-80 h-48 rounded-lg">
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectsPage;
