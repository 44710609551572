import React from 'react';
import '@react-firebase/auth';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../Components/NotFound';
import Dashboard from '../dashboardComponents/Dashboard';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import Home from '../dashboardComponents/pages/Home';
import Service from '../pages/ServicesPage';
import Faq from '../dashboardComponents/pages/Faq';
import Users from '../dashboardComponents/pages/Users';
import History from '../dashboardComponents/pages/History';
import Notification from '../dashboardComponents/pages/Notification';
import Donation from '../dashboardComponents/pages/Donation';
import Search from '../dashboardComponents/pages/Search';
import Profile from '../dashboardComponents/pages/Profile';
import Payment from '../dashboardComponents/pages/Payment';
import Account from '../dashboardComponents/pages/Account';
import Testimonials from '../dashboardComponents/pages/Testimonials';
import Garrely from '../dashboardComponents/pages/Garrely';
import Support from '../dashboardComponents/pages/Support';
import CommingSoon from '../Components/CommingSoon';
import Clients from '../pages/ClientsPage';
import Equipments from '../pages/EquipmentsPage';
import MissionPage from '../pages/MissionPage';
import VisionPage from '../pages/VisionPage';

import ContactPage from '../pages/ContactPage';
import ProjectPage from '../pages/ProjectPage';

export default function index() {
  return (
    <Switch>
      <Route path="/about" exact component={AboutPage} />
      <Route path="/contact" exact component={ContactPage} />
      <Route path="/project" exact component={ProjectPage} />
      <Route path="/service" exact component={Service} />
      <Route path="/client" exact component={Clients} />
      <Route path="/equipment" exact component={Equipments} />
      <Route path="/mission" exact component={MissionPage} />
      <Route path="/vision" exact component={VisionPage} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/home" exact component={Home} />
      <Route path="/faq" exact component={Faq} />
      <Route path="/website/testimonials" exact component={Testimonials} />
      <Route path="/website/garrely" exact component={Garrely} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/settings/account" exact component={Account} />
      <Route path="/settings/finances/payment" exact component={Payment} />
      <Route path="/settings/finances/donation" exact component={Donation} />
      <Route path="/settings/notifications" exact component={Notification} />
      <Route path="/search" exact component={Search} />
      <Route path="/history" exact component={History} />
      <Route path="/support" exact component={Support} />
      <Route path="/users" exact component={Users} />
      <Route path="/commingsoon" exact component={CommingSoon} />
      <Route path="/" exact component={HomePage} />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
}
