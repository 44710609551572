import React, { useState } from 'react';
import Contact from '../Components/Contact';
import Layout from '../Components/layout/Layout';
import Image2 from '../Assets/image/our_team.png';
import TeamPage from '../Components/OurTeam';
import Gallery from '../Components/Gallery';

const AboutPage = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <Layout>
      <div className="nanum-myeongjo-regular bg-gray-50  pt-2 md:px-16 lg:px-32">
        <SectionHeader title="BACKGROUND" />
        <div className="text-gray-700 mx-auto mt-2 lg:w-3/4 leading-relaxed space-y-4 p-10">
          <p className="font-medium">
            Gaia Survey Rwanda is a drilling and water well company led by experienced engineers
            and technicians, offering innovative solutions for government and private sectors.
          </p>
          <p className="font-medium">
            The company is dedicated to meeting the demands of modern engineering industries in both
            government and private sectors. We aim to provide state-of-the-art engineering solutions
            that set our clients apart, offering a comprehensive range of services under one roof.
          </p>
          {expanded && (
            <>
              <p className="font-medium">
                The company is dedicated to meeting the demands of modern engineering industries in
                both government and private sectors. We aim to provide state-of-the-art engineering
                solutions that set our clients apart, offering a comprehensive range of services
                under one roof.
              </p>
              <p className="font-medium">
                Our team is committed to delivering exceptional solutions, from drilling and water
                supply projects to building construction and project management. We leverage our
                expertise to provide innovative solutions that meet every budget and schedule.
              </p>
            </>
          )}
          <button onClick={toggleExpand} className="text-primary font-semibold underline">
            {expanded ? 'Show Less' : 'Learn More'}
          </button>
        </div>
        <TeamPage />
        <Gallery />
        <SectionHeader title="" />
        <div className="flex justify-center mt-8">
          <img
            className="rounded-xl shadow-lg w-full lg:w-3/4 h-auto object-cover"
            src={Image2}
            alt="Our Team"
          />
        </div>
      </div>

      <Contact />
    </Layout>
  );
};

const SectionHeader = ({ title }) => (
  <div className="text-center my-3">
    <h2 className="text-3xl font-bold special-elite-regular text-gray-500 mb-1">{title}</h2>
    <hr className="w-32 h-1 mx-auto bg-yellow-400 rounded" />
  </div>
);

export default AboutPage;
