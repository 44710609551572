import React from 'react';

const LargeDBoreholes = () => {
  return (
    <section id="hero" className="w-full bg-gray-200 py-12">
      <div className="container mx-auto flex flex-col items-center px-6">
        <div className="item2 w-3/4 mx-auto text-left mb-6">
          <div className="item1 mb-6 text-Center">
            <p className="text-3xl font-bold text-yellow-400 mb-4">
              LARGE DIAMETER REVERSE CIRCULATION BOREHOLES
            </p>
          </div>
          <h1 className="text-gray-600">
            Reverse circulation is a drilling technique used to drill large diameter boreholes.
          </h1>
          <p className="text-gray-600 mb-4">
            In order to achieve the necessary up-hole velocity of the flushing medium to carry drill
            cuttings out of the borehole, the circulation system is reversed, and the flushing
            medium flows down the borehole between the borehole wall and the drill string.
          </p>
          <p className="text-gray-600 mb-4">
            It then flows rapidly up the inside of the drill string and is ejected into settling
            pits/tanks at the surface. Drill cuttings now fall out of the flushing medium, which
            then flows back down the annulus, completing the circulation procedure.
          </p>
          <p className="text-gray-600 mb-4">
            Very large diameter boreholes can be drilled using this method. We have constructed
            boreholes up to 2200mm in diameter and to a depth in excess of 80 meters using this
            technique with great success.
          </p>
          <p className="text-gray-600 mb-4">
            We have several heavy-duty large diameter drilling rigs allowing us to service client
            requirements for large diameter reverse circulation boreholes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default LargeDBoreholes;
