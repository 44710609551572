import service1 from '../Assets/image/geological-and-hydro-survey.jpg';
import service3 from '../Assets/image/AAEAAQAAAAAAAAgYAAAAJGZiNjcwZTY1LTMwMzgtNGZkNy04NmNhLTc3ZjIxNDMyNzBkMA.jpg';
import service2 from '../Assets/image/drilling-for-wells.jpg';
import service4 from '../Assets/image/services-mini-piles.jpg';
import service5 from '../Assets/image/africa-solar-generation.jpg';
import service6 from '../Assets/image/water-pump.jpg';
export const services = [
  {
    name: 'Geological and Hydro geological survey',
    description:
      'Geophysical survey considers different parameters such as hydrogeological aspects, altitude, soil types, vegetation covers… for determining the types of aquifers for the purposes of ground water feasibility, the aquifer is characterized by Porosity i.e the volume of open spaces and permeability i.e interconnections of pores and water flow potential. There are various methods used for ground water study PQWT is one of the Geoelectric Instruments for Survey Profile of the underground layer based on the difference in the conductivity of rock material, soil minerals or underground liquid. Generally used as a basis for underground water drilling. Groundwater is stored in and travels through fractures, joints, and other weaknesses in the rock Drilling should be carried out with an appropriate tool - either percussion or rotary machines will be suitable, though the latter are considerably faster.  However due to stable sub ground condition air rotary drilling is the most suitable method',
    img: service1,
    url: 'https://skill-verification.vercel.app/'
  },
  {
    name: 'Drilling of water wells',
    description:
      'Gaia Survey Rwanda provides expert drilling, completion, and rehabilitation of water wells for municipal, commercial, industrial, residential, and agricultural use. Our solutions are tailored to meet specific water needs, ensuring reliable, long-term access to clean water.',
    img: service2,
    url: 'https://bts-corp.github.io/EthFaucet/'
  },
 
  {
    name: 'Open Hole and Micro Piling',
    description:
      'We provide open-hole and micro-piling solutions for foundation stability in construction projects. Our services ensure robust structural support, catering to both commercial and residential needs with advanced engineering techniques.',
    img: service4,
    url: 'http://ether-wallet-iota.vercel.app/'
  },
  {
    name: 'Water Pipeline and Irrigation',
    description:
      'We specialize in the installation of water pipelines, ensuring efficient water transportation from storage to your property. Our team handles every step, delivering high-quality, durable, and reliable water supply systems for homes, businesses, and industries.',
    img: service3,
    url: 'http://ether-wallet-iota.vercel.app/'
  },
  
  {
    name: 'Solar Power (Photovoltaic) Grid Installation',
    description:
      'We specialize in the installation of photovoltaic solar power grids, offering sustainable energy solutions for homes, businesses, and communities. Our systems are designed to maximize efficiency and reduce reliance on traditional energy sources.',
    img: service5,
    url: 'https://marketplace-five-mu.vercel.app/'
  },
  
  {
    name: 'Water Pumps Supply and Installation',
    description:
      'We supply and install a wide range of water pumps, including submersible, solar-powered, and hand pumps. Our team ensures proper installation and functionality, delivering reliable and efficient solutions for residential, agricultural, and industrial water needs.',
    img: service6,
    url: 'https://bts-corp.github.io/Simple-Storage/'
  }
  
];
export const currentServices = [
  {
    name: 'Geological and Hydro geological survey',
    description:
      'Geophysical survey considers different parameters such as hydrogeological aspects...',
    img: service1,
    url: 'https://skill-verification.vercel.app/'
  },
  {
    name: 'Drilling of water wells',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution...',
    subDescription:
      'Gaia Survey Rwanda team is experienced in installation of water pipeline and its supply...',
    img: service2,
    url: 'https://bts-corp.github.io/EthFaucet/'
  },
  {
    name: 'Design and construction of Irrigation scheme',
    description:
      'Gaia Survey Rwanda specializes in hydro-geology surveys and borehole drilling...',
    img: service3,
    url: 'http://ether-wallet-iota.vercel.app/'
  }
];

export const historicalServices = [
  {
    name: 'Test Pumping',
    description:
      'A pumping test is a field experiment in which a well is pumped at a controlled rate...',
    img: service4,
    url: 'https://marketplace-five-mu.vercel.app/'
  },
  {
    name: 'Pump Installations',
    description:
      'Gaia Survey Rwanda pump technicians have expertise ranging from the installation of submersible pumps...',
    img: service6,
    url: 'https://bts-corp.github.io/Simple-Storage/'
  }
];
